<template>
    <div class="details-wrapper">
        <global-page-back
            :detailPageTitle="formObj.lable"
            @handlerGlobalType="handlerGlobalType"
        ></global-page-back>
        <div class="details-content">
            <table-data
                v-loading="loadingTable"
                :to-top="false"
                id="table"
                ref="table"
                :config="tableConfig"
                :tableData="tableData"
            >
                <template v-slot:answerInfo="slotData">
                    <div v-if=" slotData.data.answerInfo ">
                        <span v-if="!isImage">{{ slotData.data.answerInfo }}</span>
                        <el-image
                            v-else
                            style="width: 50px; height: 50px"
                            :src="slotData.data.answerInfo"
                            :preview-src-list="[slotData.data.answerInfo]"
                        >
                        </el-image>
                    </div>
                    <div v-else>-</div>
                </template>
                <template v-slot:formTitle="slotData">
                    <div
                        style="color: #3c7fff; cursor: pointer"
                        @click="openDetails(slotData.data)"
                    >
                        {{ slotData.data.name }}
                    </div>
                </template>
            </table-data>
            <Pagination
                :page.sync="pageData.pageNum"
                :limit.sync="pageData.pageRow"
                :total="pageData.total"
                @pagination="changeTableDataGet"
            />
        </div>
    </div>
</template>

  <script>
import {Loading, Error, Pagination} from "common-local";
import GlobalPageBack from "@/components/scrollWrapper/Sub/GlobalPageBack/index.vue";
import TableData from "@/components/scrollWrapper/Sub/TableData";
import {mapState} from "vuex";
export default {
    props: ["formObj", "id"],
    components: {
        GlobalPageBack,
        TableData,
        Loading,
        Error,
        Pagination,
    },
    data() {
        return {
            detailPageTitle: "详情",
            loadingTable: false,
            tableData: [{}],
            pageData: {
                pageNum: 1,
                pageRow: 20,
                total: 0,
            },
            tableConfig: {
                thead: [
                    {
                        label: "填表姓名",
                        // labelWidth: '180px',
                        minWidth: "200px",
                        prop: "filledName",
                        align: "center",
                        type: "function",
                        callBack: (row) => {
                            return row.filledName ? row.filledName : "-";
                        },
                    },
                    {
                        label: "所在组织架构",
                        // labelWidth: '180px',
                        minWidth: "200px",
                        prop: "organName",
                        align: "center",
                        type: "function",
                        callBack: (row) => {
                            return row.organName ? row.organName : "-";
                        },
                    },
                    {
                        label: "填写内容",
                        minWidth: "200px",
                        type: "slot",
                        slotName: "answerInfo",
                        prop: "answerInfo",
                        tooltip: true,
                        align: "center",
                    },
                ],
                height: "",
                check: false,
            },

            isImage: false,
        };
    },
    mounted() {


        if (this.formObj.compType == "upload") {
            this.isImage = true;
        }

        this.tableConfig.height =
            document.body.clientHeight -
            document.getElementById("table").offsetTop -
            146;

        this.getListData();
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
            groupId: (state) => state.approvalManagementGroupId,
        }),
    },

    methods: {
        handlerGlobalType() {
            this.$emit("changeComp", {
                comp: "OverallResult",
            });
        },
        changeTableDataGet() {
            this.getListData();
        },
        getListData() {
            this._fet("/school/schoolCollectDetails/list", {
                schoolId: this.schoolId,
                formId: this.id,
                titleKey: this.formObj.uid,
                type: "2",
                pageNum: this.pageData.pageNum,
                pageRow: this.pageData.pageRow,
            }).then((res) => {
                console.debug(res, "res");
                if (res.data.code == 200) {
                    console.debug(res.data.data.list, "res.data.data.list");
                    this.tableData = res.data.data.list;
                    this.pageData.total = res.data.data.total;
                }else {
                    this.$message.error(res.data.message);
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.details-wrapper {
    .details-content {
        height: calc(100vh - 180px);
        background-color: #fff;
        margin-top: 10px;
    }
}
</style>
