<template>
    <div>
        <div
            class="status"
            v-if="status == '1'"
        >
            <div class="wfb"></div>
            <div>未提交</div>
        </div>
        <div
            class="status"
            v-if="status == '2'"
        >
            <div class="ytj"></div>
            <div>已提交</div>
        </div>
        <div
            class="status"
            v-if="status == '3'"
        >
            <div class="yjs"></div>
            <div>无效答卷</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "StatusCardUser",
    props: ["status"],
};
</script>

<style lang="scss" scoped>
.status {
    display: flex;
    align-items: center;
    justify-content: center;
    .wfb {
        width: 6px;
        height: 6px;
        background: #3c7fff;
        border-radius: 50%;
        margin-right: 5px;
    }
    .ytj {
        width: 6px;
        height: 6px;
        background: #fa7719;
        border-radius: 50%;
        margin-right: 5px;
    }
    .yjs {
        width: 6px;
        height: 6px;
        background: #b7b7b7;
        border-radius: 50%;
        margin-right: 5px;
    }
}
</style>